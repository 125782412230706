.bubble {
    --r: 25px;
    /* the radius */
    --t: 30px;
    /* the size of the tail */

    padding: calc(2 * var(--r) / 3);
    -webkit-mask: radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,
        conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2) calc(var(--r) / -2) padding-box,
        radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space padding-box;
    background: border-box;
    margin-top: 10px;
}

.left {
    --_d: 0%;
    border-left: var(--t) solid #0000;
    margin-right: var(--t);
    place-self: start;
    text-align: left;
    background-color: #000;
    color: #fff;
}

.right {
    --_d: 100%;
    border-right: var(--t) solid #0000;
    margin-left: var(--t);
    place-self: end;
    text-align: right;
    background-color: #00f;
    color: #fff;
}