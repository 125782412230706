.MuiDialog-paperWidthSm {
    max-width: 600px;
    flex: 1;
}
.blur-screen {
    filter: blur(4px);  
    pointer-events: none;  
  }
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;  
    
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners for the thumb */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent; /* Color of the scrollbar track */
  }