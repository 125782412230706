*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
a{
  text-decoration: none !important;

}
li{
  list-style: none !important;
}

@media print {
  .MuiDrawer-root,
  .MuiAppBar-root {
      display: none !important;
  }
}

@media print {
  .MuiToolbar-root,
  .MuiBottomNavigation-root {
      display: none !important;
  }
}

@media print {

    html, body {
      height:100vh; 
      margin: 0 !important; 
      padding: 0 !important;
      overflow: hidden;
    }

}
