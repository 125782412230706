@import '../../scss/typographic.scss';
@import '../../scss/mixins.scss';

.navbar{
    position: absolute;
    width: 100%;
    top: 0;
    background-color: transparent;
    .navbar-toggler{
        border-color: transparent !important;
        background-color: #79E7F4;
        width: 50px;
        height: 50px;
     
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
        display: none;
        @include max-breakpoints(tablet){
            display: flex;
            align-items: center;
        }
        .toggle-icon-bar{
            color: rgb(117, 102, 102);
            font-size: 30px;
            text-align: center;
        }
    }
    .navbar-brand{
        img{
            @include max-breakpoints(mobile){
                width: 60px;
            }
        }
    }
            .nav-item{
                display: flex;
                align-items: center;
                @include max-breakpoints(tablet){
                    padding-top: 15px;
                }
                .nav-link{
                    font-size: 20px;
                    @include Roboto-Regular;
                    line-height: 24px;
                    padding-left: 50px;
                    color: #fff;
                    &:hover{
                        color: rgb(221 193 193 / 70%);
                    }
                    &:focus{
                        color: #fff;
                    }
                   @include max-breakpoints(notebook){
                       padding-left: 30px;
                   }
                   @include max-breakpoints(tablet){
                    //    color: #27527F;
                   }
                }
                .create-btn{
                    width: 152px;
                    height: 56px;
                    background-color: transparent;
                    border: 1px solid #79E7F4;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    padding: 10px;
                    margin-left: 58px;
                    color: #79E7F4;
                    transition: all .5s ease-in-out;
                    &:hover{
                        background-color: #79E7F4;
                        color: #000;
                    }
                    @include max-breakpoints(tablet){
                        margin-left: 30px;
                        margin-top: 25px;
                    }
                }
           
        
    }
}
.navbar-collapse{
    @include max-breakpoints(tablet){
        background: #27527ff5;
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0px;
        top: 75px;
        z-index: 12;
    }
}